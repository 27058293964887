<template>
    <div>
        <div class="mb-10 pa-5 white pt-10">
            <h1 class="text-center">Eth <span class="underline-text">Transactions</span></h1>
      <p class="text-center mt-3 mb-10" style="color: #909090;">A snapshot of your eth transactions</p>
      <div class="text-right mb-10 d-flex justify-end">
        <!-- <v-btn small white rounded class="teal--text white pl-5 pr-5"><v-icon>mdi-plus</v-icon>Add Missing Transactions</v-btn> -->
        <export-excel
    :data   = "wallet_transactions"><v-btn small rounded class="teal white--text ml-5">Export Transactions</v-btn> </export-excel>
      </div>
      
      <v-simple-table class="no-lines-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <!-- <th class="text-left">Chain</th> -->
      
              <th class="text-left">Timestamp</th>
              <th class="text-left">In Amount</th>
              <th class="text-left">In Currency</th>
              <th class="text-left">Out Amount</th>
              <th class="text-left">Out Currency</th>
              <th class="text-left">Type</th>
              <th class="text-left">Fee</th>
              <th class="text-left">Fee Currency</th>
              <th class="text-left">Tx Hash</th>
            </tr>
          </thead>
          <tbody class="no-lines-body">
            <tr
              v-for="(item,index) in wallet_transactions"
              :key="item.name"
              style="font-family: 'Poppins', sans-serif"
            >
            <td class="py-6">{{ index+1 }}</td>
              <!-- <td>
                <div class="d-flex align-center">
                  <img
                    src="@/assets/ethereum.png"
                    class="rounded-circle"
                    width="20"
                    height="20"
                  />
                  <div class="ms-3">
                    <p class="ma-0">ETH</p>
                    
                  </div>
                </div>
              </td> -->

        
              <td>
                <p class="mb-0 fw-normal fs-4" style="min-width: 150px;">{{ item.date }}</p>
              </td>
              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.in_amount }}</p>
              </td>
              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.in_currency }}</p>
              </td>

              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.out_amount }}</p>
              </td>
              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.out_currency }}</p>
              </td>
              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.type }}</p>
              </td>
              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.fee }}</p>
              </td>

              <td>
                <p class="mb-0 fw-normal fs-4">{{ item.fee_currency }}</p>
              </td>
              <td style="max-width: 300px;">
                <a :href="item.tx_link" target="_blank"><p class="mb-0 fw-normal fs-4" style="white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis" >{{ item.hash }}</p></a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    </div>
</template>


<script>
import Axios from 'axios'
export default {
    data(){
        return{
            wallet_transactions : []
        }
    },
    mounted(){
        this.fetchTransactions()
    },
    methods : {
        async fetchTransactions(){
            let url = "https://api.staging.cryptotaxsoft.com/api/v1/wallet-explorer/transactions?wallet-address=0x73098f3849b6e7099c078823d9e9017385695677"
            let {data} = await Axios.get(url)
            this.wallet_transactions = data
        }
    }
}
</script>

<style scoped>
.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -20px;
  right: 0;
  stroke: 3px;
  width: 250px;
  height: 30px;
  background-image: url("../assets/welcome.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}</style>